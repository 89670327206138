import { AxiosError } from 'axios';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

import { IPostData } from '@/redux/feed/model';

import { FILE_BASE_URL, postRequest } from '../api/api';

async function regSw() {
  if (typeof navigator !== 'undefined' && 'serviceWorker' in navigator) {
    const url = `${window.location.origin}/sw.js`;
    const reg = await navigator.serviceWorker.register(url, { scope: '/' });
    return reg;
  }
  throw Error('serviceworker not supported');
}

async function subscribe(serviceWorkerReg: ServiceWorkerRegistration) {
  let subscription = await serviceWorkerReg.pushManager.getSubscription();
  if (subscription === null) {
    subscription = await serviceWorkerReg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: 'BKMygktGIH09sJ2rCnhmKe5w4f3x8Gg2H0QTOrIz60yuPNujHLuW6KaAHSLA7YbYO5QMLvOYjZsUl58v7NoyQrg',
    });
  }

  await postRequest('/subscribe', { ...subscription.toJSON() }, undefined, false, true);
}

async function unsubscribe() {
  try {
    if (typeof navigator !== 'undefined') {
      const reg = await navigator.serviceWorker.ready;
      const subscription = await reg.pushManager.getSubscription();
      if (!subscription) return;
      await subscription.unsubscribe();

      await postRequest('/unsubscribe', { endpoint: subscription.endpoint }, undefined, false, true);
    }
  } catch (e) {
    Promise.reject(e);
  }
}

const validatePassword = (password: string) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return passwordRegex.test(password);
};

function validateEmail(email: string) {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
}

const validateImage = (file: File) => {
  const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
  const maxSize = 2 * 1024 * 1024;

  if (!allowedTypes.includes(file.type)) {
    return 'Only PNG, JPEG, WEBP and GIF images are allowed.';
  }

  if (file.size > maxSize) {
    return 'The selected image is too large. Please select an image that is smaller than 5MB.';
  }

  return null;
};

const throttleIteration = <T, U>(items: T[], processItem: (item: T) => Promise<U>, throttleTime: number): Promise<Promise<U>[]> => {
  return new Promise<Promise<U>[]>((res) => {
    let i = 0;
    const newPromises: Promise<U>[] = [];
    const interval = setInterval(() => {
      if (i >= items.length) {
        res(newPromises);
        clearInterval(interval);
        return;
      }
      newPromises.push(processItem(items[i]));
      i++;
    }, throttleTime);
  });
};

const debounceFunc = <T extends any[]>(func: (...args: T) => void, timeout = 300) => {
  let timer: NodeJS.Timeout | null;
  return (...args: T) => {
    clearTimeout(timer!);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
};

export const showError = (err: unknown) => {
  return (err as AxiosError<{ message: string }>).response?.data.message || 'Something went wrong please try again later';
};

export const cn = (...classes: ClassValue[]) => twMerge(clsx(...classes));

export { regSw, subscribe, unsubscribe, validatePassword, validateEmail, validateImage, throttleIteration, debounceFunc };

export const AWSImageConverter = (url: string) => {
  return `${FILE_BASE_URL}/${url}`;
};

export const formatChannelName = (name: string) => {
  const words = name.trim().split(' ');
  if (words.length === 1) {
    const firstLetter = name.at(0)?.toUpperCase() || '';
    const lastLetter = name.at(-1)?.toUpperCase() || '';
    return `${firstLetter}${lastLetter}`;
  }
  return words.map((word) => word.charAt(0).toUpperCase()).join('');
};

export const isUrlLink = (content: string) => {
  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
  return urlPattern.test(content);
};

export const channelNameAbbr = (name: string) => {
  return `${name?.at(0)?.toUpperCase()}${name?.at(-1)?.toUpperCase()}`;
};

export const checkFileExtension = (fileType: string) => {
  const fileExt = fileType.startsWith('image') ? 'image' : fileType.startsWith('video') ? 'video' : fileType.startsWith('tenor') ? 'tenor' : 'other';
  return fileExt;
};

export const getPostCategoryName = (post: IPostData) => {
  const postCategories = {
    news: 'NettyNews',
    drop: 'NettyDrop',
  } as const;
  return post.category ? postCategories[post.category] : post.user.profile_url;
};

export const extractHashtags = (text: string) => {
  const hashtagRegex = /#/g;
  const formattedText = text.replace(hashtagRegex, '');

  return formattedText;
};

export const addHashtagTags = (text: string) => {
  const regex = /(^|[^<])#([\w-]+)/g;

  const replacedText = text.replace(regex, (match, precedingChar, hashtag) => {
    if (precedingChar === '<') {
      return match;
    }
    const anchorTag = document.createElement('a');
    anchorTag.href = `/hashtag/${hashtag}`;
    anchorTag.textContent = `#${hashtag}`;
    anchorTag.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
    });
    return precedingChar + anchorTag.outerHTML;
  });

  return replacedText;
};

export const checkStaging = () => process.env.NODE_ENV === 'development' || process.env.NEXT_PUBLIC_SITE_URL.includes('staging');
