import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { io as ClientIO } from 'socket.io-client';

type TSocket = ReturnType<typeof ClientIO>;

type SocketContextType = {
  socket: null | TSocket;
  isConnected: boolean;
};

const SocketContext = createContext<SocketContextType>({
  socket: null,
  isConnected: false,
});

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const [isConnected, setIsConnected] = useState(false);

  const socket = useRef<any>();

  useEffect(() => {
    socket.current = ClientIO(process.env.NEXT_PUBLIC_NETTY_API, {
      transports: ['websocket'],
    });

    socket.current.on('connect', () => {
      setIsConnected(true);
    });

    socket.current.on('disconnect', () => {
      setIsConnected(false);
    });

    return () => {
      socket.current.disconnect();
    };
  }, []);

  return <SocketContext.Provider value={{ socket: socket.current, isConnected }}>{children}</SocketContext.Provider>;
};
