import { usePathname } from 'next/navigation';

import { pagesHeader } from '@/utils/constant';
import { cn } from '@/utils/helper';

const NestedNavs = () => {
  const pathname = usePathname();
  const nestedHeader = pagesHeader.find((page) => page.path.includes(pathname));
  const ComponentIcon = nestedHeader?.icon ?? <></>;

  return (
    <>
      {nestedHeader ? (
        <div className='pl-6 pt-4 sm:pt-10 pb-6 flex items-center gap-2'>
          <ComponentIcon className={cn('fill-primary [&_g]:fill-primary max-w-[25px] max-h-[26px]')} />
          {typeof nestedHeader.label === 'string' ? (
            <span className='text-xl/none text-dark-blue font-semibold'>{nestedHeader.label}</span>
          ) : (
            <h1 className='text-xl/none text-dark-blue font-semibold'>
              {nestedHeader.label[0]} <span className='text-xl/none text-primary font-semibold'>{nestedHeader.label[1]}</span>
            </h1>
          )}
        </div>
      ) : null}
    </>
  );
};

export default NestedNavs;
