import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

import { getCryptoNews, getNewsData } from '@/api/api';
import { getBlockChainMenu, getCategoryMenu, getUpcomingBydate } from '@/api/apoloQueries';
import { graphqlClient } from '@/api/gqlApi';

import type { FetchCryptoNewsParams, FetchNewsParams, FetchUpcomingNftsVariables, IBlockchainMenuPaylod, NewsState, IUpcomingCategoriesPayload, IUpcomingNfts, IMetaData } from './model';

export const fetchNewsData = createAsyncThunk('news/fetchNewsData', async (params: FetchNewsParams, { rejectWithValue }) => {
  try {
    const res = await getNewsData(params);

    return { currentPage: params.page, data: res.data, totalPages: res.headers['x-wp-totalpages'] };
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchCryptoNews = createAsyncThunk('news/fetchCryptoNews', async (params: FetchCryptoNewsParams, { rejectWithValue }) => {
  const { isLoadMore, ...param } = params;

  try {
    const res = await getCryptoNews(param);
    return { data: res.data, isMobile: isLoadMore, total_pages: res.total_pages };
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchUpcomingNfts = createAsyncThunk('news/fetchUpcomingNfts', async (variables: FetchUpcomingNftsVariables, { rejectWithValue }) => {
  try {
    const res = await graphqlClient.request<IUpcomingNfts, FetchUpcomingNftsVariables>(getUpcomingBydate({ symbol: variables.symbol, category: variables.category }), variables);
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchBlockChainMenu = createAsyncThunk('news/fetchBlockChainMenu', async (_, { rejectWithValue }) => {
  try {
    const res = await graphqlClient.request<IBlockchainMenuPaylod>(getBlockChainMenu);
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchCategoryMenu = createAsyncThunk('news/fetchCategoryMenu', async (_, { rejectWithValue }) => {
  try {
    const res = await graphqlClient.request<IUpcomingCategoriesPayload>(getCategoryMenu);
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

const initialState: NewsState = {
  newsData: [],
  dynamicMetadata: null,
  cryptoNews: [],
  crypoNewsTotalPages: 0,
  cryptoNewsLoading: false,
  upcomingNfts: null,
  upcomingNFTloading: false,
  upcomingBlockLoading: false,
  upcomingBlockchainMenu: null,
  upcomingCategoryLoading: false,
  upcomingCategoryMenu: null,
  newsTotalPages: 0,
  newsPage: 1,
  loading: false,
  detailLoading: false,
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    manualDetail: (state, action: PayloadAction<IMetaData>) => {
      state.dynamicMetadata = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNewsData.fulfilled, (state, action) => {
        state.loading = false;
        state.newsData = action.payload.currentPage === 1 ? action.payload.data : [...state.newsData, ...action.payload.data];
        state.newsTotalPages = Number(action.payload.totalPages);
      })
      .addCase(fetchUpcomingNfts.pending, (state) => {
        state.upcomingNFTloading = true;
      })
      .addCase(fetchUpcomingNfts.fulfilled, (state, action) => {
        state.upcomingNFTloading = false;
        state.upcomingNfts = action.payload;
      })
      .addCase(fetchBlockChainMenu.pending, (state) => {
        state.upcomingBlockLoading = true;
      })
      .addCase(fetchBlockChainMenu.fulfilled, (state, action) => {
        state.upcomingBlockLoading = false;
        state.upcomingBlockchainMenu = [{ symbol: 'All', name: 'All Blockchains' }, ...action.payload.blockchains];
      })
      .addCase(fetchCategoryMenu.pending, (state) => {
        state.upcomingCategoryLoading = true;
      })
      .addCase(fetchCategoryMenu.fulfilled, (state, action) => {
        state.upcomingCategoryLoading = false;
        state.upcomingCategoryMenu = [{ name: 'All NFTs' }, ...action.payload.categories];
      })
      .addCase(fetchCryptoNews.pending, (state) => {
        state.cryptoNewsLoading = true;
      })
      .addCase(fetchCryptoNews.fulfilled, (state, action) => {
        state.cryptoNewsLoading = false;
        state.cryptoNews = action.payload.isMobile ? [...state.cryptoNews, ...action.payload.data] : action.payload.data;
        state.crypoNewsTotalPages = action.payload.total_pages;
      });
  },
});

export const { manualDetail } = newsSlice.actions;

export default newsSlice.reducer;
