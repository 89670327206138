import { ComponentProps } from 'react';

import Link from 'next/link';

import { cn } from '@/utils/helper';

interface ICustomLinkProps extends ComponentProps<typeof Link> {
  children: React.ReactNode;
  className?: string;
}
const StyledLink: React.FC<ICustomLinkProps> = ({ children, className, ...props }) => {
  return (
    <Link className={cn('flex justify-center items-center w-[113px] h-[52px] font-bold text-white text-xl rounded-lg', className)} {...props}>
      {children}
    </Link>
  );
};
export default StyledLink;
