import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activePanel: null,
};

const panelSlice = createSlice({
  name: 'panel',
  initialState,
  reducers: {
    togglePanel: (state, action) => {
      state.activePanel = action.payload;
    },
  },
});

export const { togglePanel } = panelSlice.actions;
export default panelSlice.reducer;
