import { useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import { usePathname, useRouter } from 'next/navigation';

// import VLWrapper from '@/components/widgets/ViralLoop';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import useAuthenticate from '@/hooks/useAuthenticate';
import { useTheme } from '@/hooks/useTheme';
import { toggleTheme } from '@/redux/dark-mode/themeSlice';
import { cn } from '@/utils/helper';

import SEO from '../../SEO';
import Footer from '../Footer';
import Navbar from '../Navbar';
import MobileStickyBottomNav from '../navs/MobileStickyBottomNav';
import NestedNavs from '../NestedNavs';

const DashboardLayout = dynamic(() => import('./DasboardLayout'));

const GeneralLayout = ({ children, isPrivate, isPublic, disableMaxWidth }: { children: React.ReactNode; isPrivate: boolean; isPublic: boolean; disableMaxWidth?: boolean }) => {
  const { isAuth } = useAuthenticate();
  const { dynamicMetadata } = useAppSelector((state) => state.news);

  const [isAuthLocal, setISauthLocal] = useState<boolean | null>(null);

  const { mode } = useTheme();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const pathname = usePathname();
  let metaData;

  if (dynamicMetadata) {
    metaData = { ...dynamicMetadata };
  }
  const MetaComponent = metaData && <SEO contentType={metaData.contentType} image={metaData.image} title={metaData.title} description={metaData.description} />;

  useEffect(() => {
    if (isAuth !== null) {
      setISauthLocal(isAuth);
    }
    if (!isAuth) {
      dispatch(toggleTheme('dark'));
    }
  }, [isAuth]);

  if (isAuthLocal === null) {
    return MetaComponent || null;
  }

  if (isPublic && isAuth) {
    router.push('/portfolio');
    return null;
  }

  if (isPrivate && !isAuth) {
    router.push('/');
    return null;
  }

  const IsStyle = pathname !== '/' && pathname !== '/nft-loans' && pathname !== '/contact';
  return (
    <div id='general' className={`${mode} dark:bg-dark-mode`}>
      {MetaComponent}
      <div className='hidden lg:block'>{/* <VLWrapper /> */}</div>
      <div>
        {isAuth ? (
          <DashboardLayout>{children}</DashboardLayout>
        ) : (
          <>
            <Navbar />
            <div className='!bg-jet-black min-h-screen overflow-x-hidden'>
              <div className={cn(IsStyle ? cn(!disableMaxWidth && 'max-w-[1246px]', 'pt-[120px] w-full m-auto') : '')}>
                {pathname !== '/' && <NestedNavs />}
                {children}
              </div>
            </div>
            <Footer />
            <MobileStickyBottomNav dark={true} />
          </>
        )}
      </div>
    </div>
  );
};

export default GeneralLayout;
