import { usePathname, useRouter } from 'next/navigation';
import { Sheet } from 'react-modal-sheet';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '@/hooks/redux';
import useAuthenticate from '@/hooks/useAuthenticate';
import { useTheme } from '@/hooks/useTheme';
import { togglePanel } from '@/redux/panel/panelSlice';
import { cn } from '@/utils/helper';

import MenuModal from './MenuModal';
import { toggleMoreMenu } from '../../../redux/modal/modalSlice';
import { mobileSideBarLinks } from '../constants';

const MobileStickyBottomNav = ({ dark }: { dark?: boolean }) => {
  const pathname = usePathname();
  const router = useRouter();
  const dispatch = useDispatch();
  const { moreModal } = useAppSelector((state) => state.modal);
  const { isAuth } = useAuthenticate();
  const handleNavigate = (link: string) => {
    router.push(link);
  };
  const { mode } = useTheme();
  const theme = dark ? 'dark' : mode;
  // const modifiedMobileLinks = isAuth ? mobileSideBarLinks.filter((item) => item.link !== '/') : mobileSideBarLinks.filter((item) => item.link !== '/portfolio');

  return (
    <>
      <nav className={`${theme === 'light' ? `${!moreModal ? 'bg-light-blue-grey' : 'bg-white'}` : `${!moreModal ? '!bg-char-coal' : '!bg-jet-black'}`} dark:[&_button]:!bg-transparent fixed bottom-[23px]  rounded-[47px] right-5 left-5 flex items-center justify-between  overflow-x-auto z-[2000] dark:border px-6 pt-4 pb-[13px] lg:hidden`}>
        {mobileSideBarLinks.map(({ Icon, link, title, modal, isPrivate }) => {
          return (
            <button
              key={title}
              className={cn('focus:outline-none last:text-center w-max flex flex-col justify-end items-center text-white h-full')}
              onClick={() => {
                if (modal || moreModal) {
                  dispatch(toggleMoreMenu());
                  return;
                }
                if (isAuth || isPrivate) {
                  handleNavigate(link);
                } else if (!modal) {
                  handleNavigate('/login');
                }
                dispatch(togglePanel(null));
              }}
            >
              {<Icon className={`${pathname === link ? '!fill-primary [&>g]:!fill-primary [&_path]:!fill-primary' : theme === 'dark' ? 'fill-white [&>g]:fill-white [&>path]:fill-white' : 'fill-dark-blue'} w-5 h-5`} />}
              <span className={`${pathname === link ? 'text-primary' : theme === 'light' ? 'text-dark-blue' : ' text-white'} text-xs mt-1`}>{title}</span>
            </button>
          );
        })}
      </nav>
      <Sheet isOpen={moreModal} onClose={() => dispatch(toggleMoreMenu())} detent='content-height' className={`${theme}-sheet lg:hidden`}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <MenuModal />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
};

export default MobileStickyBottomNav;
