import { useEffect } from 'react';

import Router from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

export const usePageLoading = () => {
  useEffect(() => {
    NProgress.configure({
      minimum: 0.3,
      easing: 'ease',
      speed: 800,
      showSpinner: false,
    });
    const routeEventStart = () => {
      return NProgress.start();
    };
    const routeEventEnd = () => {
      return NProgress.done();
    };

    Router.events.on('routeChangeStart', routeEventStart);
    Router.events.on('routeChangeComplete', routeEventEnd);
    Router.events.on('routeChangeError', routeEventEnd);
    return () => {
      Router.events.off('routeChangeStart', routeEventStart);
      Router.events.off('routeChangeComplete', routeEventEnd);
      Router.events.off('routeChangeError', routeEventEnd);
    };
  }, []);

  return null;
};
