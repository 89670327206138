import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

import { IAcceptJoinBody, IChannelActivityParams, IChannelData, IChannelJoins, IChannelMemberParams, IChannelParams, IChannelState, IRejectJoinBody, TInviteStatus } from './model';
import { userChannel, getChannels, getSingleChannel, getUserInvites, getChannelMembers, acceptChannelJoin, rejectChannelJoin, getSubscribedChannels, getChannelActivity } from '../../api/chatAPI';
import { IMessageList } from '../message/model';

export const fetchUserChannels = createAsyncThunk('channel/fetchUserChannels', async (_, { rejectWithValue }) => {
  try {
    const res = await userChannel();
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchChannels = createAsyncThunk('channel/fetchChannels', async (params: IChannelParams, { rejectWithValue }) => {
  try {
    const res = await getChannels(params);
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchSingleChannel = createAsyncThunk('channel/fetchChannel', async (params: { roomSlug: string; isSubChannel?: boolean }, { rejectWithValue }) => {
  try {
    const res = await getSingleChannel(params);
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchChannelInvites = createAsyncThunk('channel/fetchInvites', async (_, { rejectWithValue }) => {
  try {
    const res = await getUserInvites();
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchChannelMembers = createAsyncThunk('channel/fetchChannelMembers', async (params: IChannelMemberParams, { rejectWithValue }) => {
  try {
    const res = await getChannelMembers(params);
    return {
      ...res,
      currentPage: params.page,
    };
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const acceptJoinThunk = createAsyncThunk('channel/acceptJoin', async (body: IAcceptJoinBody, { rejectWithValue }) => {
  try {
    const { joinId, status, sender } = body;
    const res = await acceptChannelJoin({ joinId, status });

    return {
      ...res,
      user: sender,
      joinId,
    };
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const rejectJoinThunk = createAsyncThunk('channel/rejectJoin', async (body: IRejectJoinBody, { rejectWithValue }) => {
  try {
    const { joinId, status } = body;
    await rejectChannelJoin({ joinId, status });

    return joinId;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchSubscribedChannels = createAsyncThunk('channel/fetchSubscribed', async (params: IChannelParams, { rejectWithValue }) => {
  try {
    const res = await getSubscribedChannels(params);
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchChannelActivity = createAsyncThunk('channel/fetchChannelActivity', async (params: IChannelActivityParams, { rejectWithValue }) => {
  try {
    const res = await getChannelActivity(params);
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

const initialState: IChannelState = {
  channel: null,
  loading: false,
  conversationModal: false,
  invitesLoading: false,
  userChannelLoading: false,
  channelsLoading: false,
  userChannel: [],
  channels: null,
  showPinned: false,
  invites: null,
  memberLoading: 'idle',
  subscribedChannels: [],
  subscribedChannelsLoading: false,
  channelActivity: [],
  channelActivityLoading: false,
  members: {
    data: [],
    totalCount: 0,
    totalPages: 0,
  },
  pinnedMessages: [],
  menuChanel: false,
  subChanelModal: null,
};

const channelSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    handlePinnshow: (state) => {
      state.showPinned = !state.showPinned;
    },
    setInviteJoin: (state, action: PayloadAction<IChannelJoins>) => {
      if (state.channel) {
        state.channel.channelJoins = [...state.channel.channelJoins, action.payload];
      }
    },
    handleConverstaionModal: (state) => {
      state.conversationModal = !state.conversationModal;
    },
    togglePinnedMessage: (state, action: PayloadAction<IMessageList>) => {
      const foundMessage = state.pinnedMessages.find((message) => message.id === action.payload.id);
      state.pinnedMessages = foundMessage ? state.pinnedMessages.filter((pinned) => pinned.id !== foundMessage.id) : [...state.pinnedMessages, { ...action.payload, isPinned: true }];
    },
    addMyChannels: (state, action) => {
      state.userChannel = [...state.userChannel, action.payload];
    },
    addSubChannel: (state, action: PayloadAction<IChannelData>) => {
      if (state.channel) {
        state.channel.subchannels.push(action.payload);
      }
      if (state.userChannel.length) {
        state.userChannel = state.userChannel.map((userCh) => {
          if (action.payload.parentId === userCh.id) {
            return {
              ...userCh,
              subchannels: [...userCh.subchannels, action.payload],
            };
          }
          return userCh;
        });
      }
      if (state.subscribedChannels.length) {
        state.subscribedChannels = state.subscribedChannels.map((subscribedCh) => {
          if (subscribedCh.channel.id === action.payload.parentId) {
            return {
              ...subscribedCh,
              channel: {
                ...subscribedCh.channel,
                subchannels: [...subscribedCh.channel.subchannels, action.payload],
              },
            };
          }
          return subscribedCh;
        });
      }
    },
    deleteSubChannelAction: (state, action: PayloadAction<{ id: number; parentId: number }>) => {
      if (state.channel) {
        state.channel.subchannels = state.channel.subchannels.filter((sub) => sub.id !== action.payload.id);
      }
      if (state.userChannel.length) {
        const foundChannelIndex = state.userChannel.findIndex((userCh) => userCh.id === action.payload.parentId);
        if (foundChannelIndex !== -1) {
          state.userChannel[foundChannelIndex].subchannels = state.userChannel[foundChannelIndex].subchannels.filter((subCh) => subCh.id !== action.payload.id);
        }
      }
    },
    updateChannelAction: (state, action) => {
      state.channel = { ...state.channel, ...action.payload };
      if (state.userChannel) {
        state.userChannel = state.userChannel?.map((channel) => {
          if (channel.id === action.payload.id) {
            return {
              ...channel,
              ...action.payload,
            };
          }
          return channel;
        });
      }
    },
    addPinnedMessages: (state, action: PayloadAction<IMessageList[]>) => {
      state.pinnedMessages = action.payload;
    },
    deleteChannelAction: (state, action: PayloadAction<{ channelId: number }>) => {
      state.userChannel = state.userChannel?.filter((channel) => channel.id !== action.payload.channelId) ?? [];
    },
    deleteChannelTokenAction: (state, action: PayloadAction<{ tokenId: number }>) => {
      if (state.channel) {
        state.channel.channelTokens = state.channel.channelTokens.filter((token) => token.id !== action.payload.tokenId);
      }
    },
    setMessageNotification: (state, action) => {
      const { payload: message } = action;
      let foundChannelIdx = state.userChannel.findIndex((channel: { id: number }) => channel.id === message.roomId);
      if (foundChannelIdx !== -1) {
        foundChannelIdx = state.userChannel.findIndex((channel) => channel.id === message.roomId);

        if (foundChannelIdx !== -1) {
          state.userChannel[foundChannelIdx]?.messages?.push({ channelId: message.roomId, id: message.id } as any);
        }
      } else {
        foundChannelIdx = state.subscribedChannels.findIndex((channel) => channel.channel.id === message.roomId);
        if (foundChannelIdx !== -1) {
          state.subscribedChannels[foundChannelIdx]?.channel?.messages.push({ channelId: message.roomId, id: message.id } as any);
        }
      }
    },
    markAsReadAction: (state, action) => {
      const { channelId, messageId } = action.payload;
      const foundChannelIdx = state.userChannel.findIndex((channel) => channel.id === channelId);
      if (foundChannelIdx !== -1) {
        const foundMessageIdx = state.userChannel[foundChannelIdx].messages.findIndex((message) => message.id === messageId);
        if (foundMessageIdx !== -1) {
          state.userChannel[foundChannelIdx].messages.splice(foundMessageIdx, 1);
        }
      } else {
        const foundSubChannelIdx = state.subscribedChannels.findIndex((channel) => channel.channel.id === channelId);
        const foundMessageIdx = state.subscribedChannels[foundSubChannelIdx].channel.messages.findIndex((message) => message.id === messageId);
        if (foundMessageIdx !== -1) {
          state.subscribedChannels[foundSubChannelIdx].channel.messages.splice(foundMessageIdx, 1);
        }
      }
    },
    unsubscribeAction: (state, action: PayloadAction<{ channelId: number }>) => {
      if (state.subscribedChannels?.length) {
        state.subscribedChannels = state.subscribedChannels.filter((subscribed) => subscribed.channel.id !== action.payload.channelId);
      }
    },
    updateMemberRoleAction: (state, action: PayloadAction<{ memberId: number; role: 'User' | 'Moderator' }>) => {
      // if (state.channel) {
      //   state.channel.channelMembers = state.channel.channelMembers.map((member) => {
      //     if (member.id === action.payload.memberId) {
      //       return {
      //         ...member,
      //         role: action.payload.role,
      //       };
      //     }
      //     return member;
      //   });
      // }
      if (state.members?.data.length) {
        state.members.data = state.members.data.map((member) => {
          if (member.id === action.payload.memberId) {
            return {
              ...member,
              role: action.payload.role,
            };
          }
          return member;
        });
      }
    },
    deleteMemberAction: (state, action: PayloadAction<{ memberId: number }>) => {
      if (state.members?.data.length) {
        state.members.data = state.members.data.filter((member) => member.id !== action.payload.memberId);
      }
    },
    clearChannels: (state) => {
      state.channels = [];
    },
    closeMenuChanel: (state) => {
      state.menuChanel = !state.menuChanel;
    },
    closeSubModal: (state, action) => {
      state.subChanelModal = action.payload;
    },
    handleInvitesAction: (state, action: PayloadAction<{ inviteId: number; status: TInviteStatus }>) => {
      if (state.invites) {
        state.invites = state.invites.filter((invite) => invite.id !== action.payload.inviteId);
      }
    },
    addChannelMemberAction: (state, action) => {
      if (state.channel) {
        state.channel.channelMembers.push(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserChannels.pending, (state) => {
        state.userChannelLoading = true;
      })
      .addCase(fetchUserChannels.fulfilled, (state, action) => {
        state.userChannelLoading = false;
        state.userChannel = action.payload;
      })
      .addCase(fetchChannels.pending, (state) => {
        state.channelsLoading = true;
      })
      .addCase(fetchChannels.fulfilled, (state, action) => {
        state.channelsLoading = false;
        state.channels = action.payload;
      })
      .addCase(fetchSingleChannel.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSingleChannel.fulfilled, (state, action) => {
        state.loading = false;
        state.channel = action.payload;
      })
      .addCase(fetchChannelInvites.pending, (state) => {
        state.invitesLoading = true;
      })
      .addCase(fetchChannelInvites.fulfilled, (state, action) => {
        state.invitesLoading = false;
        state.invites = action.payload;
      })
      .addCase(fetchChannelMembers.pending, (state) => {
        state.memberLoading = 'pending';
      })
      .addCase(fetchChannelMembers.fulfilled, (state, action) => {
        state.memberLoading = 'success';
        state.members =
          action.payload.currentPage === 1
            ? action.payload
            : {
                ...state.members,
                data: [...state.members.data, ...action.payload.data],
              };
      })
      // .addCase(acceptJoinThunk.fulfilled, (state, action) => {
      //   if (state.members && state.channel) {
      //     state.members = [...state.members, action.payload];
      //     state.channel.channelJoins = state.channel.channelJoins.filter((request) => request.id !== action.payload.joinId);
      //   }
      // })
      .addCase(rejectJoinThunk.fulfilled, (state, action) => {
        if (state.channel) {
          state.channel.channelJoins = state.channel.channelJoins.filter((request) => request.id !== action.payload);
        }
      })
      .addCase(fetchSubscribedChannels.pending, (state) => {
        state.subscribedChannelsLoading = true;
      })
      .addCase(fetchSubscribedChannels.fulfilled, (state, action) => {
        state.subscribedChannelsLoading = false;
        state.subscribedChannels = action.payload;
      })
      .addCase(fetchChannelActivity.pending, (state) => {
        state.channelActivityLoading = true;
      })
      .addCase(fetchChannelActivity.fulfilled, (state, action) => {
        state.channelActivityLoading = false;
        state.channelActivity = action.payload;
      });
  },
});

export const {
  handlePinnshow,
  setInviteJoin,
  handleConverstaionModal,
  togglePinnedMessage,
  closeSubModal,
  closeMenuChanel,
  addPinnedMessages,
  deleteChannelAction,
  clearChannels,
  deleteChannelTokenAction,
  addMyChannels,
  updateChannelAction,
  unsubscribeAction,
  setMessageNotification,
  markAsReadAction,
  addSubChannel,
  updateMemberRoleAction,
  deleteMemberAction,
  deleteSubChannelAction,
  handleInvitesAction,
  addChannelMemberAction,
} = channelSlice.actions;
export default channelSlice.reducer;
