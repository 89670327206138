import ChannelIcon from '@/assets/icons/icon-channel.svg';
import CryptoPricesIcon from '@/assets/icons/icon-cryptho-logo.svg';
import DocsIcon from '@/assets/icons/icon-docs.svg';
import LoanIcon from '@/assets/icons/icon-dolar.svg';
import DonutIcon from '@/assets/icons/icon-donut.svg';
import GraphIcon from '@/assets/icons/icon-graph.svg';
import IconListDots from '@/assets/icons/icon-listdots.svg';
import MessageIcon from '@/assets/icons/icon-message-dots.svg';
import MessagesIcon from '@/assets/icons/icon-message-small.svg';
import MoreIcon from '@/assets/icons/icon-more.svg';
import NewUserIcon from '@/assets/icons/icon-new-user.svg';
import NewsIconSmall from '@/assets/icons/icon-news-small.svg';
import NFTSalesIcon from '@/assets/icons/icon-nft-small.svg';
import NFTIcon from '@/assets/icons/icon-nft.svg';
import MenuIcon from '@/assets/icons/icon-portfolio-new.svg';
import PortfolioIcon from '@/assets/icons/icon-portfolio-small.svg';
import RequestIcon from '@/assets/icons/icon-request.svg';
import RewardsIcon from '@/assets/icons/icon-rewards.svg';
import SDKIcon from '@/assets/icons/icon-sdk.svg';
import IntagramIcon from '@/assets/icons/icon-social-insta.svg';
import LinkedInIcon from '@/assets/icons/icon-social-ln.svg';
import TwitterIcon from '@/assets/icons/icon-social-x.svg';
import YoutubeIcon from '@/assets/icons/icon-social-youtube.svg';
import ToggleIcon from '@/assets/icons/icon-toggles.svg';
import WalletIcon from '@/assets/icons/icon-wallet-small.svg';
import { cn } from '@/utils/helper';

export const MORE_LINK_ITEMS = [
  {
    link: 'API/SDK',
    path: '/api-sdk',
  },
  {
    link: 'Docs',
    path: 'https://docs.nettyworth.io/docs/nettyworth',
  },
  {
    link: 'About us',
    path: '/about',
  },
  {
    link: 'Contact',
    path: '/contact',
  },
  // {
  //   link: 'Advertise',
  //   path: '/nft-advertising',
  // },
];

export const MORE_LINK_ITEMSMENU = [
  {
    link: 'Portfolio',
    path: '/',
  },
  {
    link: 'NFT Loans',
    path: '/nft-loans ',
  },
  {
    path: '/crypto-prices',
    link: 'Crypto',
  },
  {
    path: '/nft-news',
    link: 'Netty News',
  },
  {
    link: 'Upcoming',
    path: '/upcoming-Ethereum-nft-launches',
  },
  {
    path: '/nft-top-sales',
    link: 'NFT Sales',
  },
  {
    path: '/nft-education',
    link: 'Education',
  },
  {
    path: '/podcasts',
    link: 'Videos',
  },
];

export const MORE_LEGAL_LINK_ITEMS = [
  {
    link: 'Privacy Policy',
    path: '/privacy',
  },
  {
    link: 'Terms of service',
    path: '/service',
  },
];

export const MOBILE_MORE_LINK_ITEMS = [
  'Learn',
  'Feedback',
  'About us',
  'Contact',
  //  'Advertise',
  'Advertise Collection',
];

export const sideBarLinks = [
  {
    link: '/portfolio',
    Icon: GraphIcon,
    title: 'Portfolio',
  },
  {
    link: '/',
    Icon: GraphIcon,
    title: 'Portfolio',
  },
  {
    link: '/crypto-prices',
    Icon: DonutIcon,
    title: 'Crypto',
  },
  {
    link: '/nft-top-sales',
    Icon: NFTIcon,
    title: 'NFT',
  },
  // {
  //   link: '/feed',
  //   Icon: HomeIcon,
  //   title: 'Feed',
  // },
  // {
  //   link: '/upcoming-Ethereum-nft-launches',
  //   Icon: CalendarIcon,
  //   title: 'Drops',
  // },
  {
    link: '/nft-loans',
    Icon: LoanIcon,
    title: 'Loans',
  },
  {
    link: 'https://rewards.nettyworth.io/',
    Icon: ({ className, ...props }: any) => RewardsIcon({ ...props, className: cn(className, 'text-dark-blue dark:text-white dark:[&_path]:fill-none') }),
    title: 'Rewards',
  },
  // {
  //   link: '/communities',
  //   Icon: CommunityLgIcon,
  //   title: 'Communities',
  // },
];

export const mobileSideBarLinks = [
  // {
  //   link: '/',
  //   Icon: PortfolioIcon,
  //   title: 'Portfolio',
  //   headerText: null,
  //   isPrivate: true,
  // },
  {
    link: '/portfolio',
    Icon: PortfolioIcon,
    title: 'Portfolio',
    headerText: 'Portfolio',
  },
  {
    link: '/nft-loans',
    Icon: LoanIcon,
    title: 'Loan',
    isPrivate: true,
    headerText: 'Loan',
  },
  {
    link: 'https://rewards.nettyworth.io/',
    Icon: RewardsIcon,
    title: 'Rewards',
    headerText: 'Rewards',
  },
  {
    link: '/crypto-prices',
    Icon: CryptoPricesIcon,
    title: 'Crypto',
    headerText: 'Crypto Market',
    isPrivate: true,
  },
  {
    link: '',
    Icon: MoreIcon,
    title: 'Menu',
    modal: true,
  },
];
export const socialMediaIcons = [
  {
    path: 'https://twitter.com/nettyworthapp',
    icon: TwitterIcon,
    media: 'Twitter',
  },
  {
    path: 'https://www.linkedin.com/company/nettyworth',
    icon: LinkedInIcon,
    media: 'Linkedin',
  },
  {
    path: 'https://www.youtube.com/channel/UCS728rmdZt-IudjmVQOwN4g',
    icon: YoutubeIcon,
    media: 'Youtube',
  },
  {
    path: 'https://www.instagram.com/nettyworth.io',
    icon: IntagramIcon,
    media: 'Instagram',
  },
];

interface ILinks {
  link: string;
  title: string;
}
export const staticNavbarLinks: ILinks[] = [
  {
    link: '/login',
    title: 'Portfolio',
  },
  {
    link: '/nft-loans',
    title: 'NFT Loans',
  },
  {
    link: '/crypto-prices',
    title: 'Crypto',
  },
  {
    link: '/nft-news',
    title: 'Netty News',
  },
  {
    link: '/upcoming-Ethereum-nft-launches',
    title: 'Upcoming',
  },
  {
    link: '/nft-top-sales',
    title: 'NFT Sales',
  },
  {
    link: '/podcasts',
    title: 'Videos',
  },
];
export const footerResources: ILinks[] = [
  {
    link: '/nft-news/what-are-nfts',
    title: 'What are NFTs',
  },
  {
    link: '/nft-news/nft-glossary-101',
    title: 'NFT Glossary',
  },
  {
    link: '/podcasts',
    title: 'Podcast',
  },
  {
    link: '/nft-education',
    title: 'Learn',
  },
];
export const footerUpcomings: ILinks[] = [
  {
    link: '/upcoming-Ethereum-nft-launches',
    title: 'Upcoming Ethereum NFTs',
  },
  {
    link: '/upcoming-Solana-nft-launches',
    title: 'Upcoming Solana NFTs',
  },
  {
    link: '/upcoming-Cardano-nft-launches',
    title: ' Upcoming Cardano NFTs',
  },
  {
    link: '/upcoming-free-nft-mint',
    title: 'Upcoming Free NFT Mints',
  },
  {
    link: '/nft-advertising',
    title: 'List your NFT Collection!',
  },
];

export const channelRoomNavigation = (channelId: string, access: boolean, subChannelLink?: string) => {
  const roomLinks = [
    // {
    //   label: 'dashboard',
    //   icon: MoreIcon,
    //   link: `/channel/${channelId}/dashboard`,
    // },
    {
      label: 'settings',
      icon: ToggleIcon,
      link: subChannelLink ?? `/channel/${channelId}/settings`,
    },
  ];
  if (access && !subChannelLink) {
    roomLinks.push({
      label: 'invite',
      icon: NewUserIcon,
      link: `/channel/${channelId}/invite`,
    });
  }
  return roomLinks;
};

export const menuLinks = [
  {
    link: '/portfolio',
    Icon: PortfolioIcon,
    title: 'Portfolio',
    headerText: 'Portfolio',
    private: true,
    privateLink: true,
  },
  {
    link: '/crypto-prices',
    Icon: CryptoPricesIcon,
    title: 'Crypto',
    headerText: 'Crypto Market',
  },
  {
    link: '/nft-top-sales',
    Icon: NFTSalesIcon,
    title: 'NFT',
    headerText: 'NFT Sales',
  },
  {
    link: '/nft-loans',
    Icon: LoanIcon,
    title: 'Loans',
    headerText: 'Loans',
  },
  {
    link: '/api-sdk',
    Icon: (props: any) => SDKIcon({ ...props, stroke: 'white' }),
    title: 'API/SDK',
    headerText: 'API/SDK',
  },
  {
    link: 'https://docs.nettyworth.io/docs/nettyworth-white-paper',
    Icon: (props: any) => DocsIcon({ ...props, stroke: 'white' }),
    title: 'Docs',
    headerText: 'Docs',
  },
  // {
  //   link: '/nft-advertising',
  //   Icon: AdvertiseIcon,
  //   title: 'Advertise',
  //   headerText: 'Advertise',
  // },
];
export const getInTouch = [
  {
    link: '/feedback',
    Icon: RequestIcon,
    title: 'Feature Request',
  },
  {
    link: '/contact',
    Icon: MessageIcon,
    title: 'Get in Touch',
  },
];

export const moreLinksMobile = [
  {
    link: '/channel',
    Icon: ChannelIcon,
    title: 'Channel',
    headerText: 'Channel',
  },
  {
    link: '/contact',
    Icon: MessagesIcon,
    title: 'Get in Touch',
    headerText: 'Get in Touch',
  },
  {
    link: '/crypto-news',
    Icon: NewsIconSmall,
    title: 'Netty News',
    headerText: 'Netty News',
  },
  {
    link: '/feedback',
    headerText: 'Feature Request',
    Icon: RequestIcon,
    title: 'Feature Request',
  },
  {
    link: '/portfolio/nfts',
    headerText: 'My Wallet',
    Icon: WalletIcon,
    title: 'My Wallet',
  },
];

export const filterNfts = [
  {
    id: 0,
    Icon: MenuIcon,
  },
  {
    id: 1,
    Icon: IconListDots,
  },
];
export const withoutHeader = ['/feed', '/portfolio', '/crypto-prices', '/nft-top-sales', '/feedback'];
