import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

import { IChatSlice } from './model';
import { getConversations, getDirectMessage } from '../../api/chatAPI';

const initialState: IChatSlice = {
  messagesLoading: false,
  conversationData: null,
  directMessageLoading: false,
  directMessages: null,
};

export const fetchConversations = createAsyncThunk('chat/fetchConversations', async (_, { rejectWithValue }) => {
  try {
    const res = await getConversations();
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchDirectMessage = createAsyncThunk('chat/fetchDirectMessage', async (conversationId: number, { rejectWithValue }) => {
  try {
    const res = await getDirectMessage(conversationId);
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setConversation: (state, action) => {
      state.conversationData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConversations.pending, (state) => {
        state.messagesLoading = true;
      })
      .addCase(fetchConversations.fulfilled, (state, action) => {
        state.messagesLoading = false;
        state.conversationData = action.payload;
      })
      .addCase(fetchDirectMessage.pending, (state) => {
        state.directMessageLoading = true;
      })
      .addCase(fetchDirectMessage.fulfilled, (state, action) => {
        state.directMessageLoading = false;
        state.directMessages = action.payload;
      });
  },
});

export const { setConversation } = chatSlice.actions;
export default chatSlice.reducer;
