import { useEffect } from 'react';

import { getCookie } from '@/utils/cookies';

import { useAppDispatch, useAppSelector } from './redux';
import { setIsAuthenticated } from '../redux/auth/authSlice';

export default function useAuthenticate() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAppSelector((state) => state.isAuth);

  useEffect(() => {
    setTimeout(() => {
      const token = getCookie('_token');

      if (!token) {
        dispatch(setIsAuthenticated(false));
        return;
      }

      dispatch(setIsAuthenticated(true));
    });
  }, []);

  return { isAuth };
}
