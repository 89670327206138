import { Action, configureStore, ThunkAction, combineReducers } from '@reduxjs/toolkit';
import { nextReduxCookieMiddleware, wrapMakeStore } from 'next-redux-cookie-wrapper';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';

import alertReducer from './alerts/alertSlice';
// import  from './auth/authSlice';
import authReducer, { authSlice } from './auth/authSlice';
import portfolioReducer from './auth/portfolioSlice';
import channelReducer from './channel/channelSlice';
import chatReducer from './chat/directSlice';
import cryptoReducer from './crypto/cryptoSlice';
import themeReducer from './dark-mode/themeSlice';
import feedReducer from './feed/feedSlice';
import messageReducer from './message/messageSlice';
import modalReducer from './modal/modalSlice';
import myNftsReducer from './my-nfts/myNftsSlice';
import newsReducer from './news/newsSlice';
import panelReducer from './panel/panelSlice';
import profileReducer from './profile/profileSlice';
import soundReducer from './sound/soundSlice';
import topSalesReducer from './top-sales/topSalesSlice';
import userReducer from './wallet/userSlice';

export const combinedReducer = combineReducers({
  user: userReducer,
  sales: topSalesReducer,
  isAuth: authReducer,
  news: newsReducer,
  isOpen: portfolioReducer,
  modal: modalReducer,
  crypto: cryptoReducer,
  myNfts: myNftsReducer,
  theme: themeReducer,
  alert: alertReducer,
  channel: channelReducer,
  message: messageReducer,
  chat: chatReducer,
  sound: soundReducer,
  feed: feedReducer,
  profile: profileReducer,
  panel: panelReducer,
});

const reducer: typeof combinedReducer = (state, action) => {
  if (action.type === HYDRATE) {
    const { user, channel, feed, ...notPersisted } = action.payload;
    const updatedState = {
      ...state,
      ...notPersisted,
    };
    const nextState = updatedState;

    return nextState;
  }
  return combinedReducer(state, action);
};

export const makeStore = wrapMakeStore(() =>
  configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).prepend(
        nextReduxCookieMiddleware({
          subtrees: [`${authSlice.name}.isAuth`, { subtree: 'theme.mode', defaultState: 'light' }, { subtree: 'sound.mode', defaultState: 'on' }],
        })
      ),
  })
);
type Store = ReturnType<typeof makeStore>;

export type AppDispatch = Store['dispatch'];
export type RootState = ReturnType<Store['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export const wrapper = createWrapper(makeStore);
