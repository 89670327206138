import { ReactElement, ReactNode, useState } from 'react';

import { QueryClient, QueryClientProvider, HydrationBoundary, type DehydratedState } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import '../index.css';
import '../styles/globals.css';
import '../styles/theme.scss';
import '../components/layouts-and-navs/index.css';
import 'animate.css';
import Head from 'next/head';
import CookieConsent from 'react-cookie-consent';
import { Provider } from 'react-redux';

import GeneralLayout from '@/components/layouts-and-navs/landing/GeneralLayout';
import { SocketProvider } from '@/components/providers/socket-provider';
import SEO from '@/components/SEO';
import WagmiWrapper from '@/components/WagmiProvider';
import useIOSkeyboardfix from '@/hooks/useIOSkeyboardfix';
import { usePageLoading } from '@/hooks/usePageLoading';
import { wrapper } from '@/redux/store';
import { clearCookies } from '@/utils/cookies';
import { checkStaging } from '@/utils/helper';
import { IStaticMetaData } from 'types/metadata';

import { inter } from '../styles/fonts';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout & { isPrivate: boolean; isPublic: boolean; metadata?: IStaticMetaData; disableMaxWidth?: boolean };
  dehydratedState: DehydratedState;
};
const DynamicProvider = dynamic(() => import('@/components/providers/dynamic-provider'));

const ScriptProvider = dynamic(() => import('@/components/providers/script-provider'), {
  ssr: false,
});

function MyApp({ Component, ...rest }: AppPropsWithLayout) {
  const { store, props } = wrapper.useWrappedStore(rest);
  const { pageProps } = props;
  const { metadata, disableMaxWidth } = Component;

  const [queryClient] = useState(() => new QueryClient());
  usePageLoading();
  useIOSkeyboardfix();

  const getLayout =
    Component.getLayout ||
    ((page: ReactElement) => (
      <GeneralLayout disableMaxWidth={disableMaxWidth} isPrivate={Component.isPrivate} isPublic={Component.isPublic}>
        {page}
      </GeneralLayout>
    ));
  const isStagingSite = checkStaging();

  return (
    <div className={inter.className}>
      {!isStagingSite ? <ScriptProvider /> : null}
      {metadata ? (
        <SEO contentType={metadata.contentType} description={metadata.description} image={metadata.image} title={metadata.title} />
      ) : (
        <Head>
          <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' />
        </Head>
      )}
      <SocketProvider>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <HydrationBoundary state={rest.pageProps.dehydratedState}>
              <DynamicProvider>
                <WagmiWrapper>
                  <CookieConsent
                    enableDeclineButton
                    location='bottom'
                    buttonText='I understand'
                    cookieName='cookie_consent'
                    setDeclineCookie={false}
                    disableStyles
                    disableButtonStyles
                    buttonWrapperClasses='flex gap-[18px]'
                    containerClasses='fixed w-full z-[3000] bottom-0 bg-dark-blue pt-[18px] pb-[24px] flex flex-wrap justify-center items-center gap-5 px-4 md:px-0 lg:gap-[60px]'
                    buttonClasses='text-white bg-primary rounded px-[18px] py-[10px] lg:py-[15px] font-medium text-sm hover:bg-dark-blue hover:ring-inset  hover:ring-[#006FFF] hover:ring'
                    declineButtonClasses='text-white text-sm font-semibold border-[3px] border-primary rounded bg-transparent hover:bg-primary px-[23px] lg:py-3'
                    declineButtonText='I decline'
                    expires={450}
                    onDecline={() => {
                      clearCookies();
                    }}
                  >
                    <p className='text-white text-center text-sm font-normal max-w-[517px]'>We use cookies to personalize content and ads for an enhanced experience. By clicking accept, you agree to our privacy policy.</p>
                  </CookieConsent>
                  {getLayout(<Component {...pageProps} />)}
                </WagmiWrapper>
              </DynamicProvider>
            </HydrationBoundary>
          </QueryClientProvider>
        </Provider>
      </SocketProvider>
    </div>
  );
}

export default MyApp;
