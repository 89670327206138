import { http, createConfig } from 'wagmi';
import { mainnet, sepolia } from 'wagmi/chains';
import { injected, coinbaseWallet } from 'wagmi/connectors';

export const config = createConfig({
  chains: [mainnet, sepolia],

  connectors: [
    coinbaseWallet({
      appName: 'wagmi.sh',
    }),
    injected({ target: 'metaMask' }),
    injected({ target: 'phantom', shimDisconnect: true }),
    // walletConnect({
    //   projectId: process.env.NEXT_PUBLIC_WC_PROJECTID,
    //   showQrModal: true,
    //   qrModalOptions: {
    //     themeVariables: {
    //       '--wcm-z-index': '2000',
    //     },
    //   },
    // }),
  ],
  ssr: true,
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
  },
});

declare module 'wagmi' {
  interface Register {
    config: typeof config;
  }
}
