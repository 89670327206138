import { WagmiProvider } from 'wagmi';

import { config } from 'config/wagmi-config';

interface IWagmiWrapperProps {
  children: React.ReactNode;
}
const WagmiWrapper: React.FC<IWagmiWrapperProps> = ({ children }) => {
  return <WagmiProvider config={config}>{children}</WagmiProvider>;
};

export default WagmiWrapper;
