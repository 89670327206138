import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getAllUsers } from '@/api/api';
import { getUserProfile } from '@/api/feedAPI';

import { IProfileState } from './model';
import type { UserDetailRes } from '../wallet/model';

const initialState: IProfileState = {
  profile: null,
  profileLoading: 'idle',
  usersLoading: 'idle',
  users: [],
};

export const fetchProfile = createAsyncThunk<UserDetailRes, Parameters<typeof getUserProfile>[number]>('profile/fetchProfile', async (params, { rejectWithValue }) => {
  try {
    const res = await getUserProfile(params);
    return res;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const fetchNettyUsers = createAsyncThunk('users/fetchNettyUsers', async (params: Parameters<typeof getAllUsers>[number], { rejectWithValue }) => {
  try {
    const res = await getAllUsers(params);
    return res;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    incrementFollowings: (state) => {
      if (state.profile) {
        const update = { ...state.profile, followersCount: state.profile.followersCount + 1 };
        state.profile = update;
      }
    },
    decrementFollowings: (state) => {
      if (state.profile) {
        const update = { ...state.profile, followersCount: state.profile.followersCount - 1 };
        state.profile = update;
      }
    },
    clearUsers: (state) => {
      state.users = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.profileLoading = 'pending';
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.profileLoading = 'success';
        state.profile = action.payload;
      })
      .addCase(fetchNettyUsers.pending, (state) => {
        state.usersLoading = 'pending';
      })
      .addCase(fetchNettyUsers.fulfilled, (state, action) => {
        state.usersLoading = 'success';
        state.users = action.payload;
      });
  },
});

export const { incrementFollowings, decrementFollowings, clearUsers } = profileSlice.actions;
export default profileSlice.reducer;
