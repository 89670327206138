import Head from 'next/head';

import { IMetaData } from '@/redux/news/model';
import { checkStaging } from '@/utils/helper';

const SEO: React.FC<IMetaData> = ({ title, description, image, contentType }) => {
  const isStagingSite = checkStaging();
  return (
    <Head>
      <meta charSet='utf-8' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' />

      <title>{title}</title>
      <meta name='title' content={title} />
      <meta name='description' content={description} />
      {!isStagingSite && <meta name='robots' content='index, follow' />}
      <meta property='og:type' content='website' />
      <meta property='og:type' content={contentType} />
      <meta property='og:locale' content='en_US' />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={image} />
      <meta property='og:image:secure_url' content={image} />
      <meta property='og:image:width' content='300' />
      <meta property='og:image:height' content='300' />

      <meta name='twitter:site' content='@nettyworthapp' />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={image} />
    </Head>
  );
};
export default SEO;
