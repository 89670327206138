import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

import { IMessageState } from './model';
import { getMessages } from '../../api/chatAPI';

const initialState: IMessageState = {
  loading: false,
  messages: null,
};

export const fetchMessages = createAsyncThunk('message/fetchMessages', async (params: { channelId: number; userId: number }, { rejectWithValue }) => {
  try {
    const res = await getMessages(params);
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessages.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.loading = false;
        state.messages = action.payload;
      });
  },
});

export default messageSlice.reducer;
