export const feedContent = [
  {
    text: 'For you',
    value: 'My',
  },
  {
    text: 'Following',
    value: 'Following',
  },
];

export const profileContent = [
  {
    text: 'Posts',
    value: 'posts',
  },
  {
    text: 'Replies',
    value: 'replies',
  },
];
