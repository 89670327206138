import { useEffect } from 'react';

const useIOSkeyboardfix = () => {
  useEffect(() => {
    const handleIOSKeyboardFix = () => {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      if (isIOS) {
        document.querySelectorAll('input,textarea').forEach((value) => {
          setTimeout(() => {
            value.addEventListener('blur', () => {
              window.scrollTo(document.body.scrollLeft, document.body.scrollTop);
            });
          }, 0);
        });
      }
    };

    window.addEventListener('resize', handleIOSKeyboardFix);

    return () => {
      window.removeEventListener('resize', handleIOSKeyboardFix);
    };
  }, []);
};

export default useIOSkeyboardfix;
