import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

import { getFeedPosts, getPostDetail, getPostTags, getUserPosts } from '@/api/feedAPI';
import { feedContent } from '@/components/feed/constant';

import type { IFeedState, IFileProgress, IPostData, IPostDetailData, IPostDetailParams, IPostParams, IPostRes, IPostTagParams, IUserPostsParams } from './model';

const initialState: IFeedState = {
  selectedFeed: feedContent[0],
  selectedPostId: null,
  postLoading: 'idle',
  postFileLoading: {
    percent: 0,
    status: 'idle',
    totalFileSize: 0,
    uploadedMB: 0,
  },
  reactAnimationType: {},
  posts: {
    data: [],
    totalCount: 0,
    totalPages: 0,
  },
  postDetail: null,
  postDetailLoading: 'pending',
  tags: [],
  tagsLoading: 'idle',
  searchPostTag: null,
};

export const fetchFeedPost = createAsyncThunk('feed/fetchFeedPost', async (params: IPostParams, { rejectWithValue }) => {
  try {
    const res = await getFeedPosts(params);
    return { res, currentPage: params.page };
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchPostTags = createAsyncThunk('feed/fetchPostTags', async (params: IPostTagParams, { rejectWithValue }) => {
  try {
    const res = await getPostTags(params);
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchPostDetail = createAsyncThunk<{ res: IPostDetailData; currentPage: number }, IPostDetailParams>('feed/fetchPostDetail', async (params, { rejectWithValue }) => {
  try {
    const res = await getPostDetail(params);
    return { res, currentPage: params.page };
  } catch (e) {
    return rejectWithValue((e as AxiosError<{ message: string }>).response?.data.message);
  }
});

export const fetchUserPosts = createAsyncThunk<{ res: IPostRes; currentPage: number }, IUserPostsParams>('feed/fetchUserPosts', async (params, { rejectWithValue }) => {
  try {
    const res = await getUserPosts(params);
    return { res, currentPage: params.page };
  } catch (e) {
    return rejectWithValue((e as AxiosError<{ message: string }>).response?.data.message);
  }
});

const getPost = (id: number, state: IFeedState) => state.posts.data.findIndex((post) => post.id === id);

const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    selectFeedAction: (state, action: PayloadAction<(typeof feedContent)[number]>) => {
      state.selectedFeed = action.payload;
    },
    addPosts: (state, action: PayloadAction<IPostData>) => {
      const { replyToPostId, repostedId } = action.payload;
      if (replyToPostId) {
        const foundPost = state.posts.data.find((post) => post.id === replyToPostId);
        if (foundPost) {
          const replyData = {
            ...action.payload,
            repliedPost: {
              ...foundPost,
            },
            repliesCount: 0,
          };

          const updateData = [...state.posts.data];
          const addReply = updateData.map((item) => {
            if (item.id === foundPost?.id) {
              return {
                ...item,
                // reply: [...item.reply, action.payload],
                repliesCount: item.repliesCount + 1,
              };
            }
            return item;
          });
          state.posts.data = addReply;
        }
        return;
      }
      if (repostedId) {
        const foundPostIdx = state.posts.data.findIndex((post) => post.id === repostedId);
        if (foundPostIdx !== -1) {
          if (!state.posts.data[foundPostIdx].repostedByUsers) {
            state.posts.data[foundPostIdx].repostedByUsers = [];
          }
          state.posts.data[foundPostIdx].repostedByUsers.push({ id: action.payload.id });
          state.posts.data[foundPostIdx].repostedByUsers.forEach(({ id }) => {
            const otherPost = state.posts.data.findIndex((post) => post.id === id);
            if (otherPost !== -1) {
              if (state.posts.data[otherPost].reposted && state.posts.data[otherPost].reposted?.userId) {
                state.posts.data[otherPost].reposted!.repostRepostedByMe = true;
              }
            }
          });
        }
        if (foundPostIdx !== -1) {
          state.posts.data[foundPostIdx].repostedByMe = {
            user: {
              id: action.payload.user.id,
            },
          };
        }
      }
      state.posts.data.unshift(action.payload);
    },
    deletePostAction: (state, action: PayloadAction<{ postId: number; parentId?: number; repostedId?: number }>) => {
      const { postId, parentId, repostedId } = action.payload;
      if (parentId) {
        const updatedPosts = state.posts.data.map((post) => {
          if (post.id === parentId) {
            return {
              ...post,
              repliesCount: post.repliesCount - 1,
            };
          }
          return post;
        });
        state.posts.data = updatedPosts;
      }
      if (repostedId) {
        const updatedPosts = state.posts.data.map((post) => {
          if (post.id === repostedId) {
            return {
              ...post,
              repostedByMe: false,
            };
          }
          return post;
        });
        state.posts.data = updatedPosts;
      }
      state.posts.data = state.posts.data.filter((post) => post.id !== postId);
      state.posts.data = state.posts.data.filter((post) => post.reposted?.id !== postId);
    },
    undoRepost: (state, action: PayloadAction<{ id: number; repostedId: number }>) => {
      const foundPostIdx = getPost(action.payload.id, state);
      const repostedIdx = getPost(action.payload.repostedId, state);
      if (state.posts.data[repostedIdx].repostedByUsers) {
        state.posts.data[repostedIdx].repostedByUsers.filter(({ id }) => id !== action.payload.repostedId);
        state.posts.data[repostedIdx].repostedByUsers.forEach(({ id }) => {
          const otherPost = state.posts.data.findIndex((post) => post.id === id);
          if (otherPost !== -1) {
            if (state.posts.data[otherPost].reposted) {
              state.posts.data[otherPost].reposted!.repostRepostedByMe = true;
            }
          }
        });
      }
      state.posts.data[repostedIdx].repostedByMe = false;
      state.posts.data.splice(foundPostIdx, 1);
    },
    toggleReactAction: (state, action: PayloadAction<{ postId: number; userId: number }>) => {
      const { postId, userId } = action.payload;
      const foundPostIdx = state.posts.data.findIndex((post) => post.id === postId);
      if (foundPostIdx !== -1) {
        const foundReactionIdx = state.posts.data[foundPostIdx].postLikes.findIndex((like) => like.userId === userId);
        if (foundReactionIdx !== -1) {
          if (state.posts.data[foundPostIdx].repostedByUsers) {
            state.posts.data[foundPostIdx].repostedByUsers.forEach(({ id }) => {
              const foundOuterPostIdx = state.posts.data.findIndex((post) => post.id === id);
              if (foundOuterPostIdx !== -1) {
                state.posts.data[foundOuterPostIdx].reposted?.postLikes.splice(foundReactionIdx, 1);
              }
            });
          }
          state.posts.data[foundPostIdx].postLikes.splice(foundReactionIdx, 1);
          state.reactAnimationType[postId] = 'rise';
        } else {
          if (state.posts.data[foundPostIdx].repostedByUsers) {
            state.posts.data[foundPostIdx].repostedByUsers.forEach(({ id }) => {
              const foundOuterPostIdx = state.posts.data.findIndex((post) => post.id === id);
              if (foundOuterPostIdx !== -1) {
                state.posts.data[foundOuterPostIdx].reposted?.postLikes.push(action.payload);
              }
            });
          }
          state.posts.data[foundPostIdx].postLikes.push(action.payload);
          state.reactAnimationType[postId] = 'drop';
        }
      }
    },
    toggleBookMarkAction: (state, action) => {
      const { postId } = action.payload;
      const foundPostIdx = getPost(postId, state);
      if (foundPostIdx !== -1) {
        const foundBookmarkIdx = state.posts.data[foundPostIdx].bookmarks.findIndex((bookmark) => bookmark.postId === postId);
        if (foundBookmarkIdx !== -1) {
          state.posts.data[foundPostIdx].bookmarks.splice(foundBookmarkIdx, 1);
        } else {
          state.posts.data[foundPostIdx].bookmarks.push(action.payload);
        }
      }
    },
    selectPostAction: (state, action: PayloadAction<{ postId: number }>) => {
      state.selectedPostId = action.payload.postId;
    },
    deleteSelectedPostAction: (state) => {
      state.selectedPostId = null;
    },
    editPostAction: (state, action) => {
      const { id } = action.payload;
      const foundPostIdx = getPost(id, state);
      if (foundPostIdx !== -1) {
        state.posts.data[foundPostIdx] = action.payload;
      }
      state.posts.data = state.posts.data.map((post) => {
        if (post.reposted && post.reposted.id === id) {
          return {
            ...post,
            reposted: action.payload,
          };
        }
        return post;
      });
    },
    fileLoadingAction: (state, action: PayloadAction<IFileProgress>) => {
      state.postFileLoading = action.payload;
    },
    clearTags: (state) => {
      state.tags = [];
    },
    globalTagSearch: (state, action: PayloadAction<string | null>) => {
      state.searchPostTag = action.payload;
    },
    postReplyAction: (state, action) => {
      state.posts.data = [action.payload, ...state.posts.data];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeedPost.pending, (state) => {
        state.postLoading = 'pending';
      })
      .addCase(fetchFeedPost.fulfilled, (state, action) => {
        state.postLoading = 'success';
        const { res, currentPage } = action.payload;
        state.posts.data = currentPage === 1 ? res.data : [...state.posts.data, ...res.data];
        state.posts.totalCount = res.totalCount;
        state.posts.totalPages = res.totalPages;
      })
      .addCase(fetchPostDetail.pending, (state) => {
        state.postDetailLoading = 'pending';
      })
      .addCase(fetchPostDetail.fulfilled, (state, action) => {
        const {
          res: { reply, repliesCount },
          currentPage,
        } = action.payload;
        state.postDetailLoading = 'success';
        const parentReplies = [...action.payload.res.parentReplies, action.payload.res].map((item) => {
          return {
            ...item,
            mainPost: true,
          };
        });

        const withParent = parentReplies.length ? parentReplies : [{ ...action.payload.res, mainPost: true }];

        state.posts.data = currentPage > 1 ? [...state.posts.data, ...reply] : [...withParent, ...reply];
        state.posts.totalCount = repliesCount;
      })
      .addCase(fetchPostDetail.rejected, (state) => {
        state.postDetailLoading = 'failed';
      })
      .addCase(fetchUserPosts.pending, (state) => {
        state.postLoading = 'pending';
      })
      .addCase(fetchUserPosts.fulfilled, (state, action) => {
        state.postLoading = 'success';
        const { res, currentPage } = action.payload;
        state.posts.data = currentPage === 1 ? res.data : [...state.posts.data, ...res.data];
        state.posts.totalCount = res.totalCount;
        state.posts.totalPages = res.totalPages;
      })
      .addCase(fetchPostTags.pending, (state) => {
        state.tagsLoading = 'pending';
      })
      .addCase(fetchPostTags.fulfilled, (state, action) => {
        state.tagsLoading = 'success';
        state.tags = action.payload;
      });
  },
});

export const { selectFeedAction, addPosts, toggleReactAction, undoRepost, toggleBookMarkAction, deletePostAction, selectPostAction, deleteSelectedPostAction, editPostAction, fileLoadingAction, clearTags, globalTagSearch, postReplyAction } = feedSlice.actions;
export default feedSlice.reducer;
