import { postRequest, getRequest, putRequest, deleteRequest } from './api';
import { IChannelData, IAddMemberBody, IChannelTokenBody, IChannelInviteBody, IChannelInvite, IChannelInviteData, InviteHandlerBody, IChannelParams, IChannelMemberParams, IChannelJoins, IChannelMembers, IChannelTokens, IChannelActivityParams, TUserRoles, IDeleteMemberBody, IPopularChannelRes, IPopularChannelParams } from '../redux/channel/model';
import { IMessageData, IURLMetadata } from '../redux/message/model';

export const createChannel = async (body: Record<string, any>): Promise<Omit<IChannelData, 'channelMembers'>> => {
  const res = await postRequest('/channels', body, undefined, false, true);
  return res;
};

export const createSubChannel = async (body: Pick<IChannelData, 'name' | 'chanel_type' | 'invite_code'> & { parentId: number }) => {
  const res = await postRequest('/channels/subChannel', body, undefined, false, true);
  return res;
};

export const updateChannel = async (body: Record<string, any>): Promise<Omit<IChannelData, 'channelMembers'>> => {
  const res = await putRequest('/channels', body, undefined, true);
  return res;
};

export const deleteChannelImage = async (body: { channelId: number; imageName: string }) => {
  const res = await putRequest('/channels/delete-image', body, undefined, true);
  return res;
};

export const deleteChannel = async (body: { channelId: number }): Promise<{ channelId: number }> => {
  const res = await postRequest('/channels/delete-channel', body, undefined, false, true);
  return res;
};

export const unsubscribeFromChannel = async (body: { channelId: number; userId: number }): Promise<{ channelId: number }> => {
  const res = await postRequest('/channels/unsubscribe', body, undefined, false, true);
  return res;
};

export const deleteChannelToken = async (body: { tokenId: number }): Promise<{ tokenId: number }> => {
  const res = await postRequest('/channels/delete-token', body, undefined, false, true);
  return res;
};

export const userChannel = async (): Promise<IChannelData[]> => {
  const res = getRequest('/channels/userChannels', {}, false, true);
  return res;
};

export const getChannels = async (params: IChannelParams): Promise<IChannelData[]> => {
  const res = getRequest('/channels', { params }, false, true);
  return res;
};

export const getSingleChannel = async (params: { roomSlug: string; isSubChannel?: boolean }): Promise<IChannelData> => {
  const { roomSlug, isSubChannel } = params;
  const res = getRequest(`/channels/${roomSlug}`, { params: { isSubChannel } }, false, true);
  return res;
};

export const getPopularChannels = async (params: IPopularChannelParams): Promise<IPopularChannelRes> => {
  const res = getRequest('/channels/popular', { params }, false, true);
  return res;
};

export const getSubscribedChannels = async (params?: IChannelParams) => {
  const res = getRequest('/channels/subscribe', { params }, false, true);
  return res;
};

export const getMessages = async (params: { channelId: number; userId: number; isPinned?: boolean }): Promise<IMessageData[]> => {
  const res = getRequest('/messages', { params }, false, true);
  return res;
};

export const markMessageRead = async (body: { messageId: number; userId: number }) => {
  const res = await postRequest('/messages/mark-read', body, undefined, false, true);
  return res;
};

export const addChannelMembers = async (body: IAddMemberBody) => {
  const res = await postRequest('/channelMembers', body, undefined, false, true);
  return res;
};

export const getChannelMembers = async (params: IChannelMemberParams) => {
  const res = await getRequest('/channelMembers', { params }, false, true);
  return res;
};

export const updateChannelMember = async (body: { memberId: number; role: Exclude<TUserRoles, 'Admin'> }) => {
  const res = await postRequest('/channelMembers/update', body, undefined, false, true);
  return res;
};

export const deleteChannelMember = async (body: IDeleteMemberBody) => {
  const res = await deleteRequest('/channelMembers', body, undefined, true);
  return res;
};

export const addChannelTokens = async (body: IChannelTokenBody) => {
  const res = await postRequest('/channels/token', body, undefined, false, true);
  return res;
};

export const sendChannelInvite = async (body: IChannelInviteBody) => {
  const res = await postRequest('/channels/sendInvite', body, undefined, false, true);
  return res;
};

export const getUserInvites = async () => {
  const res = await getRequest('/channels/invite', {}, false, true);
  return res;
};

export const getChannelInvites = async (params: Pick<IChannelInvite, 'channelId' | 'status'>): Promise<IChannelInviteData[]> => {
  const res = await getRequest('/channels/channelInvite', { params }, false, true);
  return res;
};

export const handleInvites = async (body: InviteHandlerBody) => {
  const res = await putRequest('/channels/invite', body, undefined, true);
  return res;
};

export const sendChannelJoin = async (
  body: Omit<IChannelInviteBody, 'receiverId'>
): Promise<{
  message: string;
  join: Omit<IChannelJoins, 'sender'>;
}> => {
  const res = await postRequest('/channels/join', body, undefined, false, true);
  return res;
};

export const acceptChannelJoin = async (body: { status: 'accepted'; joinId: number }): Promise<IChannelMembers> => {
  const res = await putRequest('/channels/join', body, undefined, true);
  return res;
};

export const rejectChannelJoin = async (body: { status: 'rejected'; joinId: number }): Promise<{ message: string }> => {
  const res = await putRequest('/channels/join', body, undefined, true);
  return res;
};

export const joinByInviteCode = async (body: { inviteCode: string }): Promise<{ channelId: number; channelName: string; channelToken: IChannelTokens[] }> => {
  const res = await postRequest('/channels/check-invite-code', body, undefined, false, true);
  return res;
};

export const acceptInviteCode = async (body: { inviteCode: string }): Promise<{ channelId: number; channelName: string; channelSlug: string }> => {
  const res = await postRequest('/channels/accept-invite-code', body, undefined, false, true);
  return res;
};

export const getFriendRequest = async () => {
  const res = await getRequest('/users/friend-request', {}, false, true);
  return res;
};

export const getSendedFriendRequest = async () => {
  const res = await getRequest('/users/friend-request-send', {}, false, true);
  return res;
};

export const deleteFriend = async (body: { friendId: number }): Promise<{ friendId: number }> => {
  const res = await deleteRequest('/users/friend', body, undefined, true);
  return res;
};

export const getConversations = async () => {
  const res = await getRequest('/chat/conversations', {}, false, true);
  return res;
};

export const getDirectMessage = async (conversationId: number) => {
  const res = await getRequest(`/chat/conversations/${conversationId}`, {}, false, true);
  return res;
};

export const createRoom = async (body: {}) => {
  const res = await postRequest('/chat/conversation', body, undefined, false, true);
  return res;
};

export const getChannelActivity = async (params: IChannelActivityParams) => {
  const res = await getRequest('/channels/activity', { params }, false, true);
  return res;
};

export const getUrlMetada = async (params: { url: string }): Promise<IURLMetadata> => {
  const res = await getRequest('/messages/metadata', { params }, false, true);
  return res;
};
