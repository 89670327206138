import Image from 'next/image';
import Link from 'next/link';

import { socialMediaIcons } from './constants';

const footerLinks = [
  {
    text: 'Contact',
    to: '/contact',
  },
  {
    text: 'Terms of Service',
    to: '/service',
  },
  {
    text: 'Privacy Notice',
    to: '/privacy',
  },
];
const Footer = () => {
  return (
    <div className='pt-5 lg:pt-0 !bg-midnight-blue lg:!bg-jet-black'>
      <div className='max-w-[1110px] w-full m-auto !bg-transparent lg:pt-7 pb-[94px] lg:pb-[67px] flex justify-between px-5 xl:px-0 flex-col md:flex-row'>
        <div className='!bg-transparent lg:mt-[29px]'>
          <Image src={'/logo-dark.svg'} width={223} height={47} alt='netty-logo' priority quality={100} className='object-cover min-w-[223px] h-[47px]' />
          <p className='mt-5 font-normal text-base/7 text-white hidden md:flex'>Copyright ©NettyWorth {new Date().getFullYear()}</p>
        </div>
        <div className='!bg-transparent'>
          <p className='text-lg/[37px] font-medium text-white mb-3 mt-2 sm:mt-0'>Follow us</p>
          <div className='!bg-transparent flex gap-[19px]'>
            {socialMediaIcons.map((social) => {
              const SocialIcon = social.icon;

              return (
                <a href={social.path} target='_blank' key={social.path} className='flex justify-center items-center' rel='noreferrer'>
                  <SocialIcon className='fill-primary' />
                </a>
              );
            })}
          </div>
          <div className='flex items-center flex-wrap gap-3 !bg-transparent'>
            {footerLinks.map(({ text, to }) => {
              return (
                <Link href={to} key={to} className='font-light text-base/7 text-white mt-[10px] sm:mt-[13px] hover:!text-primary'>
                  {text}
                </Link>
              );
            })}
          </div>
          <p className='mt-4 font-normal text-base/7 text-white flex md:hidden'>Copyright ©NettyWorth {new Date().getFullYear()}</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
