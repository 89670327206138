import type { IFollowCheckParams, IPostDetailData, IPostDetailParams, IPostParams, IPostTagData, IPostTagParams, IUserFollowersParams, IUserPostsParams } from '@/redux/feed/model';
import { IUserFollowersData } from '@/redux/wallet/model';

import { getRequest, postRequest } from './api';

export const getFeedPosts = async (params: IPostParams) => {
  const res = await getRequest('/post', { params }, false, true);
  return res;
};

export const getUserPosts = async (params: IUserPostsParams) => {
  const res = await getRequest('/post/user', { params }, false, true);
  return res;
};

export const getPostTags = async (params: IPostTagParams): Promise<IPostTagData[]> => {
  const res = await getRequest('/post/tags', { params }, false, true);
  return res;
};

export const getUserProfile = async (params: Pick<IUserPostsParams, 'profile_url'>) => {
  const res = await getRequest(`/users/profile/${params.profile_url}`, {}, false, true);
  return res;
};

export const getUserFollowers = async (profile_url: string, params: IUserFollowersParams): Promise<IUserFollowersData> => {
  const res = await getRequest(`/users/profile/${profile_url}/followers`, { params }, false, true);
  return res;
};

export const getUserFollowings = async (profile_url: string, params: IUserFollowersParams): Promise<IUserFollowersData> => {
  const res = await getRequest(`/users/profile/${profile_url}/followings`, { params }, false, true);
  return res;
};

export const getPostDetail = async (params: IPostDetailParams): Promise<IPostDetailData> => {
  const { postId, ...restParams } = params;
  const res = await getRequest(`/post/${postId}`, { params: restParams }, false, true);
  return res;
};

export const savePost = async (body: { postId: number; action: 'create' | 'delete'; userId: number }) => {
  const res = await postRequest('/post/save-post', body, undefined, false, true);
  return res;
};

export const sendReport = async (body: { postId: number; content: string }) => {
  const res = await postRequest('/post/send-report', body, undefined, false, true);
  return res;
};

export const checkUserFollow = async (params: IFollowCheckParams) => {
  const res = await getRequest('/users/profile/following', { params }, false, true);
  return res;
};

export const uploadPostFiles = async (body: FormData, handleProgress: (progress: number) => void) => {
  const res = await postRequest(
    '/post/upload-files',
    body,
    {
      onUploadProgress: (ev) => {
        if (ev.total) {
          const progressPercent = Math.round((ev.loaded * 100) / ev.total);
          handleProgress(progressPercent);
        }
      },
    },
    false,
    true
  );
  return res;
};
