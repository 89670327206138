import { GraphQLClient } from 'graphql-request';

import { HYGRAPH_URL } from './api';

const NETTYWORTH_GRAPHQL_URL = 'https://top-sales.nettyworth.io/graphql';
// const NETTYWORTH_GRAPHQL_URL = 'http://localhost:3002/graphql';

export const newGraphqlClient = new GraphQLClient(NETTYWORTH_GRAPHQL_URL);
export const graphqlClient = new GraphQLClient(HYGRAPH_URL, {
  headers: {
    authorization:
      'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2NjYwNDI0MjYsImF1ZCI6WyJodHRwczovL2FwaS11cy1lYXN0LTEuaHlncmFwaC5jb20vdjIvY2w5ZDhqeG5vMDRvNDAxdW84bnN2NHVpaS9tYXN0ZXIiLCJtYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiMzhiN2ZiZTgtNDNkMS00MjRiLTgzNzYtY2Y2ZGUyOTYwODkwIiwianRpIjoiY2w5ZGFuN21pMDgydDAxdXUxaG54NDh0biJ9.Aryxu8axVaACoPhEgfTGOQmtg27HU64ZF6uqjf6ekKhg_jFC6ZsgRvS5mlL0f1TcExr1g47MCxWEp5aPVD3WYP90I2Np0ZP9dUvPEXTJ7RLBMl5coe3-dPZxgJhpb2y_0a9k7SG7hMtYSR1nIJQbyNTelQNH1UP2cJ-P-msNB6jI9R7UkroRh5XaGA--ftSRf3nA7BI906xjbsa8r39SZHho78ArXpaGkBATP9uRllKntLbYajLi1uBKaVpZqmtOytOvKa1SDHkygCWJKfaL_GtM6328pLhOhNsArvZmD2uxKTc22LCbjfV2nmrxyOCVYbkkrfCgaV3C-K5oZZUHUBtyw2-U3DZb1RCkc87AOtr_a70Nse4XdxU_WmnJ1kotKKPJP0oB3upFecjIXF88_TiDqsv9asvsDuYje9fL_58NnyG3EnjOD9QQK4hk17h0iTrBRL2NOYyLH77x3jDreAbLpJ4-P-fOY7q_WlQ_XY6JMLaC30a3TuhQXpyVxyYB01KXRjctu4NaCsC1uUR_IvwI8Z1gBzkdLsI3gsb7B5Hw_JPGvqtZ1J0F2_SGju1yFxEenDdYBVcz55LsbzjfdBX_XJMZRwvNbVG_EY3_cIkv2w5NLpMcut9rtIXfNutKOxO4Lir8PjenptWA7mgb3cE1-ml-jr_Pj8Z28t7Spek',
  },
});
