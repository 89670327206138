import { getRequest } from '@/api/api';

import { IByMarketPlaceParams, ICollectionByIDsParams, ICollectionDataByID, ICollectionDetail, ITopCollectionParams, ITopCollectionsData } from './types/simpleHash';
import { getSimpleHash } from './walletApi';

export const getCollectiosByID = async (params: ICollectionByIDsParams): Promise<ICollectionDetail[]> => {
  const res = await getRequest('/simplehash/collections/ids', { params }, false, true);
  return res.collections;
};

export const getTopCollections = async (params: ITopCollectionParams): Promise<ITopCollectionsData> => {
  const res = await getRequest('/simplehash/top-collections', { params }, false, true);
  return res;
};

export const getCollectionsByMarketPlace = async (params: IByMarketPlaceParams) => {
  const res = await getSimpleHash<ICollectionDataByID, typeof params>('collections/marketplace/opensea', params);
  return res.collections;
};
