import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

import { IAlertState, INotification } from './model';
import { getNotifications } from '../../api/api';

const initialState: IAlertState = {
  notification: null,
  loading: false,
};

export const fetchUserNotification = createAsyncThunk<INotification>('alert/fetchfetchUserNotification', async (_, { rejectWithValue }) => {
  try {
    const res = await getNotifications();
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

const alertSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    markSeeNotifAction: (state) => {
      if (state.notification) {
        state.notification = {
          ...state.notification,
          notifications: state.notification.notifications.map((notif) => ({
            ...notif,
            seen: 1,
          })),
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserNotification.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserNotification.fulfilled, (state, action) => {
      state.loading = false;
      state.notification = action.payload;
    });
  },
});

export const { markSeeNotifAction } = alertSlice.actions;
export default alertSlice.reducer;
