import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getCookie } from '../../utils/cookies';

const initialState = {
  mode: getCookie('sound') as 'on' | 'off',
};

const soundSlice = createSlice({
  name: 'sound',
  initialState,
  reducers: {
    toggleSound: (state, action: PayloadAction<'on' | 'off'>) => {
      state.mode = action.payload;
    },
  },
});

export const { toggleSound } = soundSlice.actions;
export default soundSlice.reducer;
