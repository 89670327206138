import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

import { getCryptoCurrency, getCryptoFilters, getCryptoMap, getCryptoMetadata } from '@/api/api';

import { CryptoCurrencyParams, ICryptoData } from './model';

export const fetchCryptoCurrency = createAsyncThunk('crypto/fetchCryptoCurrency', async (params: CryptoCurrencyParams, { rejectWithValue }) => {
  try {
    const res = await getCryptoCurrency(params);
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchCryptoCurrencyMount = createAsyncThunk('crypto/fetchCryptoCurrencyMount', async (params: CryptoCurrencyParams, { rejectWithValue }) => {
  try {
    const res = await getCryptoCurrency(params);

    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchCryptofilters = createAsyncThunk('crypto/fetchCryptofilters', async (_, { rejectWithValue }) => {
  try {
    const res = await getCryptoFilters();
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchCryptoMetadata = createAsyncThunk('crypto/fetchCryptoMetadata', async (params: { symbol: string }, { rejectWithValue }) => {
  try {
    const res = await getCryptoMetadata(params);
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchtCryptoMap = createAsyncThunk('crypto/fetchtCryptoMap', async (params: { start?: number; limit?: number; symbol: string }, { rejectWithValue }) => {
  try {
    const res = await getCryptoMap(params);
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

const initialState: ICryptoData = {
  cryptoData: {
    data: [],
    favorites: [],
    status: null,
  },
  cryptoDetails: null,
  currencyData: {
    data: [],
    status: null,
  },
  cryptoMap: null,
  cryptoStats: null,
  statsLoading: false,
  cryptoMetadata: null,
  loading: false,
  filtersLoading: false,
  detailsLoading: false,
};

const cryptoSlice = createSlice({
  name: 'crypto',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCryptoCurrencyMount.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCryptoCurrencyMount.fulfilled, (state, action) => {
        state.cryptoData.data = [...action.payload.data];
        state.cryptoData.favorites = action.payload.favorites;
        state.loading = false;
      })
      .addCase(fetchCryptoCurrency.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCryptoCurrency.fulfilled, (state, action) => {
        state.cryptoData.data = [...state.cryptoData.data, ...action.payload.data];
        state.cryptoData.status = action.payload.status;
        state.loading = false;
      })
      .addCase(fetchCryptofilters.pending, (state) => {
        state.filtersLoading = true;
      })
      .addCase(fetchCryptofilters.fulfilled, (state, action) => {
        state.filtersLoading = false;
        state.currencyData.data = action.payload.data;
        state.currencyData.status = action.payload.status;
      })
      .addCase(fetchCryptoMetadata.fulfilled, (state, action) => {
        state.cryptoMetadata = action.payload.data;
      })
      .addCase(fetchtCryptoMap.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchtCryptoMap.fulfilled, (state, action) => {
        state.loading = false;
        state.cryptoMap = action.payload.data;
      });
  },
});

export default cryptoSlice.reducer;
