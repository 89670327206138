import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getUserDetail, getUserFav } from '@/api/api';
import { intervals } from '@/utils/constant';

import type { IUser, Trigger, UserDetailRes } from './model';
import { getCollectiosByID } from '../../simplehash_api/collectionApi';
import { ICollectionDetail } from '../../simplehash_api/types/simpleHash';
import { getWalletTraitFloor } from '../../simplehash_api/walletApi';
import { IData as UserCryptoFavourite } from '../crypto/model';

export const fetchUserDetail = createAsyncThunk('fetchUserDetail', async (_, { rejectWithValue }) => {
  try {
    const res = await getUserDetail();
    return res;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const fetchUserFavouriteCrypto = createAsyncThunk('user/fetchfavcrypto', async (_, { rejectWithValue }) => {
  try {
    const res = await getUserFav<'CryptoSales'>({ category: 'CryptoSales' }).then((result) => {
      const data = Object.values(result);
      return data;
    });
    return res;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const fetchFavNFT = createAsyncThunk('user/fetchFavNFT', async (collection_ids: string, { rejectWithValue }) => {
  try {
    const res = await getCollectiosByID({
      collection_ids,
    });
    return res;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const fetchWalletTraitFloor = createAsyncThunk('user/fetchWalletTraitFloor', async (wallet_address: string, { rejectWithValue }) => {
  try {
    const res = await getWalletTraitFloor({ wallet_address });
    return res;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const initialState: IUser = {
  wallets: [],
  userDetail: null,
  userDetailLoading: false,
  nettyWorth: 0,
  NFTValue: 0,
  collectionStats: null,
  nftInfo: null,
  loading: false,
  NFTInventory: [],
  trigger: 'portfolioCTA',
  selectedNettyWorth: 'FLOOR',
  walletStatsLoading: false,
  walletStats: null,
  historicalLoading: false,
  walletHistorical: null,
  walletCryptoData: null,
  walletCryptoDataLoading: false,
  walletNFTCollections: null,
  walletNFTCollectionsLoading: false,
  graphDataInterval: intervals[0],
  favouriteCryptoLoading: false,
  favouriteCrypto: [],
  favouriteNFTLoading: false,
  favouriteNFT: [],
  walletTraitFloorLoading: 'idle',
  walletTraitFloorPrice: 0,
  walletModalOpen: false,
  activeChain: 'ethereum',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getCollectionStats: (state, action) => {
      state.collectionStats = action.payload;
    },
    getNftInfo: (state, action) => {
      state.nftInfo = action.payload;
    },
    getWalletDetail: (state, action) => {
      state.wallets = [action.payload];
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setTriggerMethod: (state, action: PayloadAction<Trigger>) => {
      state.trigger = action.payload;
    },
    setSelectedNettyWorth: (state, action: PayloadAction<typeof initialState.selectedNettyWorth>) => {
      state.selectedNettyWorth = action.payload;
    },
    setUserImage: (state, action) => {
      if (state.userDetail) {
        state.userDetail.profile_picture = action.payload;
      }
    },
    clearUserData: (state) => {
      state.userDetail = null;
    },
    setgraphDataSelected: (state, action) => {
      state.graphDataInterval = action.payload;
    },
    addNFTFavouriteAction: (state, action: PayloadAction<ICollectionDetail>) => {
      const foundIdx = state.favouriteNFT.findIndex((nft) => nft.collection_id === action.payload.collection_id);
      if (foundIdx !== -1) {
        state.favouriteNFT.splice(foundIdx, 1);
      } else {
        state.favouriteNFT.push(action.payload);
      }
    },
    addCryptoFavouriteAction: (state, action: PayloadAction<UserCryptoFavourite>) => {
      const { id } = action.payload;
      const foundIdx = state.favouriteCrypto?.findIndex((crypto) => crypto.id === id);
      if (foundIdx !== -1) {
        state.favouriteCrypto.splice(foundIdx, 1);
      } else {
        state.favouriteCrypto.push(action.payload);
      }
    },
    toggleFollowerAction: (state, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload;
      if (state.userDetail) {
        const foundFollowerIdx = state.userDetail.following.findIndex((followUser) => followUser.id === id);
        if (foundFollowerIdx !== -1) {
          state.userDetail.following.splice(foundFollowerIdx, 1);
        } else {
          state.userDetail.following.push({ id });
        }
      }
    },
    updateCurrentUser: (state, action: PayloadAction<Partial<Omit<UserDetailRes, 'id'>>>) => {
      if (state.userDetail) {
        state.userDetail = { ...state.userDetail, ...action.payload };
      }
    },
    openWalletModal: (state, action: PayloadAction<boolean>) => {
      state.walletModalOpen = action.payload;
    },
    selectActiveChain: (state, action: PayloadAction<string>) => {
      state.activeChain = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetail.pending, (state) => {
        state.userDetailLoading = true;
      })
      .addCase(fetchUserDetail.fulfilled, (state, action) => {
        state.userDetailLoading = false;
        state.userDetail = action.payload;
      })
      .addCase(fetchUserFavouriteCrypto.pending, (state) => {
        state.favouriteCryptoLoading = true;
      })
      .addCase(fetchUserFavouriteCrypto.fulfilled, (state, action) => {
        state.favouriteCryptoLoading = false;
        state.favouriteCrypto = action.payload;
      })
      .addCase(fetchFavNFT.pending, (state) => {
        state.favouriteNFTLoading = true;
      })
      .addCase(fetchFavNFT.fulfilled, (state, action) => {
        state.favouriteNFTLoading = false;
        state.favouriteNFT = action.payload;
      })
      .addCase(fetchWalletTraitFloor.pending, (state) => {
        state.walletTraitFloorLoading = 'pending';
      })
      .addCase(fetchWalletTraitFloor.fulfilled, (state, action) => {
        state.walletTraitFloorLoading = 'success';
        state.walletTraitFloorPrice = action.payload.data;
      });
  },
});

export const { getCollectionStats, getNftInfo, getWalletDetail, openWalletModal, setLoading, setTriggerMethod, setSelectedNettyWorth, setUserImage, setgraphDataSelected, clearUserData, addNFTFavouriteAction, addCryptoFavouriteAction, toggleFollowerAction, updateCurrentUser, selectActiveChain } = userSlice.actions;
export default userSlice.reducer;
